/*! poocoo */
// worker  #######################################################################################################
const PATH = '/service-worker.js';
let isServiceWorkersSupport = ('serviceWorker' in navigator);
if (isServiceWorkersSupport) {
    navigator.serviceWorker.register(PATH).then(function () {
    }).catch(function (err) {
    });
}



// navi  #########################################################################################################
global.openNav = function() {
    document.getElementById("mySidenav").style.width = "100%";
}

global.closeNav = function () {
    document.getElementById("mySidenav").style.width = "0";
}

// Mobile  #######################################################################################################
global.isMobile = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


//// navi  #########################################################################################################
//function openNav() {
//    document.getElementById("mySidenav").style.width = "100%";
//}
//function closeNav() {
//    document.getElementById("mySidenav").style.width = "0";
//}

//// Mobile  #######################################################################################################
//function isMobile() {
//    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
//}


$(document).ready(function () {
    // cookies  ######################################################################################################
    var cookieName = "scrabble_cookies_ok";
    $("#close-cookie-warn").click(function () {
        $.cookie(cookieName, 1, { expires: 365 });
        $("#cookie-warn").hide("slow");
    });
    var scrabbleCookie = $.cookie(cookieName);
    if (scrabbleCookie !== "1") {
        $("#cookie-warn").show();
    }

    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.closebtn').click();
        }
    });
});

(function ($) {
    jQuery.fn.putCursorAtEnd = function () {
        return this.each(function () {
            $(this).focus()
            if (this.setSelectionRange) {
                var len = $(this).val().length * 2;
                this.setSelectionRange(len, len);
            }
            else {
                $(this).val($(this).val());
            }
            this.scrollTop = 999999;
        });
    };
})(jQuery);